(() => {
	const contact = document.getElementById('btn-contact');
	const body = document.querySelector('body');
	const popup = document.querySelector('.popup');
	const closePopup = document.querySelector('#btn-close-popup');
	const text = document.querySelector('#rotating-text');
	const popupText = document.querySelector('.popup-text');

	contact.addEventListener('click', () => {
		body.style.overflowY = 'hidden';
		text.classList.add('active');
		popup.classList.add('active');
		popupText.classList.add('active');
	});

	closePopup.addEventListener('click', () => {
		body.style.overflowY = 'auto';
		text.classList.remove('active');
		popup.classList.remove('active');
		popupText.classList.remove('active');
	});
})();




